import ObjectUtils from "@/utils/object-utils";
import {i18n} from "@/bootstraps/locale";
import {RequiredFieldKey} from "@/entities/specs/spec-share-request-entity";

export class CompanySettingEntity {
  public isAutoCreateIngredientFromSpec!:boolean;
  public useIntraCategory!: boolean;
  public isENumberVisible!: boolean;
  public decimalPlaceCalorie!:NutritionDecimalPoint;
  public decimalPlaceNonCalorie!: NutritionDecimalPoint;
  public accessibilityToOtherDeptResources!: AccessibilityToOtherDeptResources;
  public isDepartmentEnabled!: boolean;
  public isApprovalFlowEnabled!: boolean;
  public isAcceptedSpecReconfirmationNotificationEnabled!: boolean;
  public acceptedSpecReconfirmationNotificationType: AcceptedSpecReconfirmationNotificationType = AcceptedSpecReconfirmationNotificationType.AcceptanceDate;
  public acceptedSpecReconfirmationNotificationDays: number | null = null;
  public acceptedSpecReconfirmationReminderType: AcceptedSpecReconfirmationReminderType|null = null;
  public isAccessLogVisible!: boolean;
  public specRequestDefaultRequiredFields: RequiredFieldKey[] = [];
  public displayDeprecatedNonGmoAsNonGmo!: boolean;
  public hiddenWhenAllSameNameIngredientInvisible!: boolean;
  public enableBrotherPrint!: boolean;

  public approvalFlowForAcceptanceEnabled!: boolean;
  public approvalFlowForAcceptanceDeclineType!: ApprovalFlowSettingDeclineType;
  public approvalFlowForAcceptanceIsEditableOnProgress!: boolean;
  public approvalFlowForOwnedSpecEnabled!: boolean;
  public approvalFlowForOwnedSpecDeclineType!: ApprovalFlowSettingDeclineType;
  public approvalFlowForOwnedSpecIsEditableOnProgress!: boolean;

  public passwordMinCharLength!: number;
  public passwordMinTypeLength!: number;
  public isMfaEmailEnabled!: boolean;
  public accessibleIpAddresses!: string[];

  constructor(init:Partial<CompanySettingEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);
    this.specRequestDefaultRequiredFields = init.specRequestDefaultRequiredFields || [];
    this.accessibleIpAddresses = init.accessibleIpAddresses || [];
  }

  public get needFilterOtherDepartmentResourcesOnIndex(): boolean {
    if (!this.isDepartmentEnabled) return false;
    return this.accessibilityToOtherDeptResources == AccessibilityToOtherDeptResources.ReadableWithoutIndex ||
      this.accessibilityToOtherDeptResources == AccessibilityToOtherDeptResources.Forbidden;
  }
}

export enum AcceptedSpecReconfirmationNotificationType {
  LastUpdatedDate = 1,
  ApplicationDate = 2,
  AcceptanceDate = 3,
  ApprovalDate = 4,
}
export const AcceptedSpecReconfirmationNotificationTypeDict = {
  get [AcceptedSpecReconfirmationNotificationType.LastUpdatedDate]() { return i18n.t("最終更新日"); },
  get [AcceptedSpecReconfirmationNotificationType.ApplicationDate]() { return i18n.t("適用日"); },
  get [AcceptedSpecReconfirmationNotificationType.AcceptanceDate]() { return i18n.t("最終受領日"); },
  get [AcceptedSpecReconfirmationNotificationType.ApprovalDate]() { return i18n.t("最終承認日"); },
};
export enum AcceptedSpecReconfirmationReminderType {
  OneWeek = 1,
  TwoWeeks = 2,
  ThreeWeeks = 3,
  OneMonth = 11,
}
export const AcceptedSpecReconfirmationReminderTypeDict = {
  get [AcceptedSpecReconfirmationReminderType.OneWeek]() { return i18n.tc("common.week", 1); },
  get [AcceptedSpecReconfirmationReminderType.TwoWeeks]() { return i18n.tc("common.week", 2); },
  get [AcceptedSpecReconfirmationReminderType.ThreeWeeks]() { return i18n.tc("common.week", 3); },
  get [AcceptedSpecReconfirmationReminderType.OneMonth]() { return i18n.tc("common.month", 1); },
};

export enum NutritionDecimalPoint {
  Zero = 0,
  One = 1,
  Two = 2,
}

export enum AccessibilityToOtherDeptResources {
  Editable = 1,
  Readable = 2,
  ReadableWithoutIndex = 3,
  Forbidden = 4,
}
export const AccessibilityToOtherDeptResourcesDict = {
  get [AccessibilityToOtherDeptResources.Editable]() { return i18n.t('全データを閲覧可能、編集可能'); },
  get [AccessibilityToOtherDeptResources.Readable]() { return i18n.t('全データを閲覧可能、所属外データの編集不可'); },
  get [AccessibilityToOtherDeptResources.ReadableWithoutIndex]() { return i18n.t('所属外データを非表示、編集不可、引用可能'); },
  get [AccessibilityToOtherDeptResources.Forbidden]() { return i18n.t('所属外データを閲覧不可、編集不可、引用不可'); },
}

export enum ApprovalFlowSettingDeclineType {
  All = 1,
  WorkflowUser = 2,
}
export const ApprovalFlowSettingDeclineTypeDict = {
  get [ApprovalFlowSettingDeclineType.All]() { return i18n.t('組織内の全ユーザー'); },
  get [ApprovalFlowSettingDeclineType.WorkflowUser]() { return i18n.t('管理者 + 申請者 + 該当承認ワークフロー内の全承認ユーザー'); },
}
