export default {
  validations: {
    "文字": "文字",
    "数値を入力してください": "数値を入力してください",
    "整数を入力してください": "整数を入力してください",
    "正の整数を入力してください": "正の整数を入力してください",
    "日付を入力してください": "日付を入力してください",
    "日付形式を入力してください": "日付形式を入力してください",
    "値を入力してください": "値を入力してください",
    "この項目は必須です": "この項目は必須です",
    tel: "電話番号を入力してください（例: 01-2345-6789）",
    min: "{0}以上に設定してください",
    max: "{0}以下に設定してください",
    between: "{min}以上{max}以下に設定してください",
    decimal: {
      int: "整数部は{0}桁以下で入力してください",
      decimal: "小数部は{0}桁以下で入力してください"
    },
    prohibitedChars: "使用できない文字が含まれています「{chars}」",
  },
  auth: {
    signup: {
      agreement: "{0} と {1} に同意の上、以下のボタンから作成してください。",
    }
  },
  spec: {
    request: {
      confirm: {
        detail: "以下から提出アカウントを選択ください。詳細は、{0}をご覧ください。",
      }
    },
    specs: {
      index: {
        search_placeholder: '商品名・担当者・タグ・原材料名・添加物・アレルゲン・原産地・関連企業名などで検索',
        number_of_shared_companies: "共有企業数",
        annotation_for_filtered_by_dept: "現在、自分が所属している部署のデータのみ表示されています。詳しくは",
        annotation_for_filtered_by_dept_link: "こちらから",
      },
      detail: {
        level: "{n}次成分",
        contamination_same_factory: "同じ工場内で「{allergens}」を生産しております。",
        contamination_same_line: "同じ製造ラインで「{allergens}」を生産しております。",
        forwarding_alert: `この規格書は転送用です。<br><br>
メーカー・商社等から受け取った回収規格書の「販売者情報」のみが「あなたの組織情報」に書き換えられた状態で、自社規格書として追加されています。<br><br>
受け取った後に回収規格書が更新されたり、組織情報が変更となった場合は、画面右上の「…」（リーダーアイコン）から情報を更新してください。`,
      },
      edit: {
        guarantee_text: `本規格書は、食品衛生法などの関係法令を遵守しており、記載事項を保証するものとします。<br/><br/>本規格書に関して、関連法令などに変更が生じる場合、速やかに通知を行い、再提出の必要がある場合は、これに応じるものとします。`,
      }
    },
    self_submission: {
      detail: {
        alert: "この規格書は手動で追加されました。<br><br>" +
          "転記ミスや回収・提出先からの更新が、リアルタイムで反映されないため、食品事故につながる可能性があります。<br><br>" +
          "リアルタイムで反映するためには、提出元に「スマート食品規格書」を導入（無料）してもらい、画面上部の「回収管理」> 画面右上の「＋ 規格書の新規回収」から回収する必要があります。"
      }
    },
    receives: {
      detail: {
        display_history: "{date} 提出分を表示中",
        history: "{date} 提出分",
      },
    },
    forwarding: {
      seller_replacement: "この時、「販売者」のみ「あなたの組織の情報」に差し替えられます。あなたの組織の最新情報は、{0}から確認することが可能です。",
    },
    common: {
      left_days: "残り {days} 日",
      over_days: "{days} 日超過",
      required_approvals: "この規格書を{typeLabel}するためには、残り {restRequiredApprovalUsersLength} 名の承認が必要です。<br><br>" +
        "必要な承認者名は、{userNames}です。<br><br>" +
        "また、規格書の内容が変更されると承認はリセットされます。",
      per: "{n}当たり",
      action_by: "{by} が{action}",
      management_tag: "管理",
      displaying: "{item}を表示中",
      export_alert: '<ul>' +
        '<li>ダウンロードには、数十秒かかることがございます。</li>' +
        '<li>現在一部の添加物等が設定されている場合、エラーが発生することを確認しております。これについては、アップデートにて対応予定です。</li>' +
        '<li>このフォーマットで提出してしまうと、規格書の更新のたびに提出先の管理が必要となってしまうため、提出先に「スマート食品規格書」を導入していただいた上で、システム上で送信することをオススメ致します。</li>' +
        '</ul>',
    },
  },
  mypage: {
    company: {
      setting: {
        acceptedSpecReconfirmationNotification: "{0}から{1}日後に通知",
      },
      department: {
        delete_alert: `この部署に所属しているメンバーがいるため削除できません。<br><br>` +
          `この部署を消すためには、組織アカウント設定 > 「メンバー追加・管理」から、すべてのメンバーをこの部署の所属から外してください。`,
        delete_confirm: 'この部署を本当に削除しますか。<br><br>' +
          '削除すると、この部署に所属しているデータは管理メンバー以外に見えなくなる可能性があります。',
      },
      member: {
        edit: '{0} の権限・部署を変更する',
        delete: '{0} をメンバーから削除',
        invitation_sent: '招待メールを送信しました<br>' +
          '送信したメールのリンクをクリックすると、組織に参加することができます。<br>' +
          '届いていない場合は「迷惑メールフォルダ」などをご確認ください。',
      },
      invoice: {
        cancel_label: "解約してしまうと、登録されている「原材料」「商品」が、閲覧できなくなります。<br><br>" +
          "再度契約をいただくと、再び閲覧することが可能です。<br><br>解約した後も「{0}」までは、ご利用いただくことが可能です。",
        cancel_spec: "解約してしまうと、登録されている「規格書」「回収」「受領」が、閲覧できなくなります。<br><br>" +
          "再度契約をいただくと、再び閲覧することが可能です。<br><br>解約した後も「{0}」までは、ご利用いただくことが可能です。",
        canceled: `このプランは「{0}」に停止します。<br><br>下部から「再契約」を押していただくと、今までと変わりなくご利用が可能です。`,
        resume_label: `再度「スマート食品表示」を契約します。<br><br>登録されている「原材料」「商品」が、閲覧・編集、また新規登録が可能となります。`,
        resume_spec: `再度「スマート食品規格書」を契約します。<br><br>登録されている「規格書」「回収」「提出」が、閲覧・編集、また新規登録が可能となります。`,
        can_use_until: "{0}までは利用可能です",
      }
    }
  },
  common: {
    of: "{item}の{action}",
    select: "{item}の選択",
    name: "{item}名",
    gte: "{0]以上",
    lte: "{0]以下",
    search_result: `"{text}" の検索結果 {total}件（うち {count}件 を表示）`,
    required: "必須",
    optional: "任意",
    append: "{0}",
    week: "{n}週間",
    month: "{n}ヶ月",
  },
  utils: {
    only_japanese: '{0}',
  },
  "手動": "手　動",
  "右上「原材料の追加」から、商品を構成する「原材料」を追加しましょう！": "右上「原材料の追加」から、商品を構成</br>する「原材料」を追加しましょう！",
  "をメンバーから削除しますか？": "{0} をメンバーから削除しますか？",
  "xを削除しますか？": "{0} を削除しますか？",
  "の招待を取り消しますか？": "{0} の招待を取り消しますか？",
  "選択されたn項目を一括で削除しますか？(この操作はもとに戻せません)": "選択された{0}項目を一括で削除しますか？(この操作はもとに戻せません)",
  "xのデータ取り込みが完了しました": "{0}のデータ取り込みが完了しました",
  "この規格書データを「x」が読み取るために、当システム側で自動変換する内容、もしくは不足している内容があります。確認事項は、修正画面でも確認することができます。" :
    "この規格書データを「{0}」が読み取るために、当システム側で自動変換する内容、もしくは不足している内容があります。確認事項は、修正画面でも確認することができます。",
  "この提出依頼を取下げますか":
    "この提出依頼を取下げ（中断）ますか？相手方にも、提出が取下げられたことは明示されます。<br><br>この依頼を再び提出するには、相手方に再度依頼を送っていただく必要があります",
  "回収規格書（x）を手動作成しました。": "回収規格書（{0}）を手動作成しました。",
  "回収規格書（x）を手動更新しました。": "回収規格書（{0}）を手動更新しました。",
  "xを下書き保存しました。": "{0}を下書き保存しました。",
  "xを正規の版として保存しました。": "{0}を正規の版として保存しました。",
  "xを提出しました": "{0}を提出しました",
  "xにこの規格書の新しい版が配信されました。影響範囲を確認して、問題がなければ、通知を削除してください。":
    "{0}にこの規格書の新しい版が配信されました。影響範囲を確認して、問題がなければ、通知を削除してください。",
  "この規格書は、「最終更新日」より t日以上 が経過しています。規格書の最新状態を確認し、問題がなければ、この通知を削除してください。":
    "この規格書は、「最終更新日」より {0}日以上 が経過しています。<br><br>規格書の最新状態を確認し、問題がなければ、この通知を削除してください。",
  "お取引先「{0}」から、下記の規格書の提出依頼が届いています。": "お取引先「{0}」から、下記の規格書の提出依頼が届いています。",
  day: "日",
  days: "日",
  '承認ワークフロー「{option1}」を開始': '承認ワークフロー「{option1}」を開始',
  '承認ワークフロー「{option1}」を取り下げ': '承認ワークフロー「{option1}」を取り下げ',
  '承認ワークフロー「{option1}（{option2}）」において承認': '承認ワークフロー「{option1}（{option2}）」において承認',
  '承認ワークフロー「{option1}」の承認をキャンセル': '承認ワークフロー「{option1}」の承認をキャンセル',
  '「{option2}」を出力': '「{option2}」を出力',
}
