import ObjectUtils from "@/utils/object-utils";
import {
  requiredRule,
  typeEmailRule,
  createMinRule,
  createMaxRule,
  dateStringRule,
  requiredOnChangeRule, createMaxStringRule, dateRule
} from '@/utils/validation-rules';
import formatDate from 'date-fns/format';
import parseISO from "date-fns/parseISO";
import CompanyDepartmentEntity, {getDepartmentNames} from "@/entities/company-department-entity";
import {ValidatorRules as CompanyValidatorRules} from "@/entities/company-entity";
import {i18n} from "@/bootstraps/locale";

export default class UserEntity {
  public id!:number;
  public email!:string;
  public name!:string;
  public nameKana!:string;
  public birthday?:string;
  public companyId!:number;
  public companyRole!:CompanyRole;
  public maxReadNotificationId:number = 0;
  public createdAt!:Date;

  public canAccessAllDeptResources!: boolean;
  public departments:CompanyDepartmentEntity[] = [];

  constructor(init:Partial<UserEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);

    this.birthday = init.birthday ? formatDate(new Date(init.birthday), 'yyyy-MM-dd') : undefined;

    if (init.createdAt) {
      this.createdAt = parseISO((init as any).createdAt);
    }

    if (init.departments) {
      this.departments = init.departments;
    }
  }

  public get isCompanyAdmin(): boolean {
    return this.companyRole === CompanyRole.Admin;
  }

  public get isCompanyEditable() {
    return this.companyRole === CompanyRole.Admin ||
      this.companyRole === CompanyRole.Operator;
  }

  public get roleName(): string {
    return CompanyRoleDict[this.companyRole];
  }

  public getDepartmentNames(): string[] {
    return getDepartmentNames(this.canAccessAllDeptResources, this.departments);
  }

}

export enum CompanyRole {
  Admin = 0,
  Operator = 1,
  Viewer = 2
}
export const CompanyRoleDict = {
  [CompanyRole.Admin]: i18n.t('管理メンバー'),
  [CompanyRole.Operator]: i18n.t('編集メンバー'),
  [CompanyRole.Viewer]: i18n.t('閲覧メンバー'),
} as { [key in CompanyRole]: string };

export const ValidatorRules:any = {
  email: [requiredRule, typeEmailRule],
  password: [ requiredRule ],
  name: [requiredRule, createMaxStringRule()],
  nameKana: [requiredRule, createMaxStringRule()],
  birthday: [],
  companyRole: [requiredOnChangeRule],
  company: CompanyValidatorRules,
};
